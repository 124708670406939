import React from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faTwitterSquare,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

import footerStyles from "./footer.module.css";
const Footer = () => {
  const { site } = useStaticQuery(query);
  return (
    <footer>
      <ul className={`${footerStyles.footerList} ${footerStyles.container}`}>
        {site.siteMetadata.nav.map((navItem, index) => (
          <li key={index} className={footerStyles.footerListItem}>
            <Link className={footerStyles.footerLink} to={navItem.path + "/"}>
              {navItem.name}
            </Link>
          </li>
        ))}
        <li
          className={`${footerStyles.footerListItem} ${footerStyles.footerListCopyright}`}
        >
          © {new Date().getFullYear()} R L R Bose & Co.
        </li>
        <li
          className={`${footerStyles.footerListItem} ${footerStyles.socialLinks}`}
        >
          <Link
            to="https://www.linkedin.com/company/rlrbose/"
            target="_blank"
            className={footerStyles.socialLink}
          >
            <FontAwesomeIcon icon={faLinkedinIn}></FontAwesomeIcon>
          </Link>
          <Link
            to="https://twitter.com/rlrbose_co"
            target="_blank"
            className={footerStyles.socialLink}
          >
            <FontAwesomeIcon icon={faTwitterSquare}></FontAwesomeIcon>
          </Link>
          <Link
            to="https://www.facebook.com/R-L-R-BOSE-Co-214384249331083/"
            target="_blank"
            className={footerStyles.socialLink}
          >
            <FontAwesomeIcon icon={faFacebookSquare}></FontAwesomeIcon>
          </Link>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;

const query = graphql`
  query FooterData {
    site {
      siteMetadata {
        nav {
          name
          path
        }
      }
    }
  }
`;
