import React from "react";
import PropTypes from "prop-types";

import SEO from "./seo";
import Header from "./header";
import Footer from "./footer";
import Favicon from "./favicon";

// import layoutStyles from "./layout.module.css";

const Layout = props => {
  return (
    <div>
      <SEO title={props.seoTitle} description={props.seoDescription} />
      <Favicon />
      <Header />
      {props.children}
      <Footer />
    </div>
  );
};

export default Layout;

Layout.propTypes = {
  seoDescription: PropTypes.string.isRequired,
  seoTitle: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};
