import React, { useState } from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";

import headerStyles from "./header.module.css";
const Header = () => {
  const { site } = useStaticQuery(query);
  const [isOpen, toggleMobileNav] = useState(false);

  return (
    <header>
      <div className={headerStyles.container}>
        <Link to="/" className={headerStyles.logo}>
          <div>
            <p className={headerStyles.logoText}>R L R Bose & Co.</p>
            <p className={headerStyles.logoSubText}>Chartered Accountants</p>
          </div>
        </Link>
        <div
          className={`${headerStyles.mobileNavIcon} ${
            isOpen ? headerStyles.active : ""
          }`}
          onClick={() => toggleMobileNav(!isOpen)}
        >
          <span
            className={`${isOpen ? headerStyles.active : ""} ${
              headerStyles.top
            }`}
          ></span>
          <span
            className={`${isOpen ? headerStyles.active : ""} ${
              headerStyles.middle
            }`}
          ></span>
          <span
            className={`${isOpen ? headerStyles.active : ""} ${
              headerStyles.bottom
            }`}
          ></span>
        </div>
        <nav className={isOpen ? headerStyles.active : ""}>
          <ul className={headerStyles.navLinks}>
            {site.siteMetadata.nav.map((navItem, index) => (
              <li key={index} className={headerStyles.linkListItem}>
                <Link className={headerStyles.link} to={navItem.path + "/"}>
                  {navItem.name}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;

const query = graphql`
  query HeaderData {
    site {
      siteMetadata {
        nav {
          name
          path
        }
      }
    }
  }
`;
